import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useContext, useEffect, useState } from "react";
import ProductContext from "../../../../context/product-context";
import Carousel from "../../../material/carousel/carousel";
import CarouselItem from "../../../material/carousel/item";

export default function ProductImagesCarousel() {
    const { product } = useContext(ProductContext); 
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (product == null) return;
        setImages(product.images.map(image => getImage(image.gatsbyImageData)));
    }, [product]);

    return product == null ? null : <div>
        <Carousel className="rounded-lg">
            { images.map((image, i) => <CarouselItem key={i}>
                <GatsbyImage loading={i === 0 ? 'eager' : 'lazy'} imgClassName='rounded-lg' image={image} />
            </CarouselItem>) }
        </Carousel>
    </div>;
}