import React from 'react';
import { useContext } from 'react';
import ProductContext from '../../../../context/product-context';
import SelectOption from '../../../material/select/option/option';
import Select from '../../../material/select/select';
import ProductOptionColor from './color/color';
import ProductOptionSize from './size/size';

export function ProductOptionGeneric({ className, onChange, ...option }) {
    return <div className={className}>
        <span className='mb-1'> { option.name } </span>
        <Select type='button' onChange={onChange}>
            { option.values.map(val => <SelectOption key={val} value={val}> {val} </SelectOption>) }
        </Select>
    </div>
}

export default function ProductOption({className, ...option}) {

    const { setOption } = useContext(ProductContext);
    const handleChange = (newValue) => setOption(option.name, newValue);

    switch (option.name) {
        case 'Color': return <ProductOptionColor className={className} onChange={handleChange} {...option} />
        case 'Size': return <ProductOptionSize className={className} onChange={handleChange} {...option} />
        default: return <ProductOptionGeneric className={className} onChange={handleChange} {...option} />
    }
}