import React, { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import * as styles from './carousel.module.css'; 
import CarouselTracker from './tracker';

export const CarouselContext = createContext({
    items: []
});

export default function Carousel({
    className,
    children: items,
    width: initialWidth
}) {
    const [index, setIndex] = useState(0);
    const [width, setWidth] = useState(initialWidth ?? '100%');

    const outerContainerRef = useRef(null);
    useEffect(() => setWidth(outerContainerRef.current.clientWidth), []);
    
    const next = () => {
        if (index === items.length - 1) setIndex(0);
        else setIndex(index + 1);
    }
    const previous = () => {
        if (index === 0) setIndex(items.length - 1);
        else setIndex(index - 1);
    }
    const skip = (i) => {
        if (items[i] !== undefined) setIndex(i);
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: next,
        onSwipedRight: previous
    });

    const outerStyle = { width };
    const innerStyle = { transform: `translateX(${index * -width}px)` };

    return <CarouselContext.Provider value={{ width, next, previous, skip, index, items }}>
        <div ref={outerContainerRef} className={`${className} ${styles.outerContainer}`} style={outerStyle}>
            <div {...(items.length > 0 ? swipeHandlers : {})} className={styles.innerContainer} style={innerStyle}>
                { items }
            </div>
        </div>
        {items.length > 0 && <CarouselTracker className='mt-2' /> }
    </CarouselContext.Provider> 
}