import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { SelectContext } from '../select';

export default function SelectOption({ className, activeClassName, value, children: label }) {

    const { registerOption, unregisterOption, setValue, value: selectValue } = useContext(SelectContext);
    const ref = useRef(null);

    useEffect(() => {
        registerOption(label, value, ref);
        return () => unregisterOption(value);
    }, []);

    const handleClick = () => setValue(value);
    const isActive = selectValue == value;

    return <span  data-active={isActive ? 'active' : undefined} className={`${className} ${isActive && activeClassName}`} ref={ref} onClick={handleClick}>
        { label }
    </span>;
}