import * as React from 'react';
import { Layout } from '../components/layout/layout';
import { ProductsContextProvider } from '../context/products-context';
import ProductImages from '../components/commerce/product/images/images';
import { ProductContextProvider } from '../context/product-context';
import ProductTitle from '../components/commerce/product/title';
import ProductPrice from '../components/commerce/product/price';
import ProductOptions from '../components/commerce/product/options/options';
import ProductDescription from '../components/commerce/product/description';
import ProductBuyButton from '../components/commerce/product/buy';

export default function ProductPage({ pageContext }) {
    const product = pageContext;

    return <Layout>
        <ProductsContextProvider>
            <ProductContextProvider shopifyId={product.shopifyId}>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-6 lg:mx-56' style={{ marginBottom: '10vh' }}>
                    <div>
                        <ProductImages />
                    </div>
                    <div className='max-w-md'>
                        <ProductTitle className='text-2xl font-medium' />
                        <ProductPrice className='block text-lg mb-3' flip />
                        { !product.hasOnlyDefaultVariant && <ProductOptions /> }
                        <ProductBuyButton className='mt-3' />
                        <ProductDescription className='mt-3 block' />
                    </div>
                </div>

            </ProductContextProvider>
        </ProductsContextProvider>
    </Layout>

}