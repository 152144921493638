import React, { useMemo } from "react";
import { useContext, useState } from "react";
import ProductContext from "../../../context/product-context";
import CommerceContext from '../../../context/commerce-context';
import Btn from "../../material/btn/btn";
import { CSSTransition } from "react-transition-group";
import * as shakeAnimationStyles from '../../../styles/animations/shake.module.css';
import { useRef } from "react";

export default function ProductBuyButton({ className }) {
    const { product, activeVariant, filteredVariants } = useContext(ProductContext);
    const { addCartLine } = useContext(CommerceContext);

    const [hint, setHint] = useState(null);

    const buyActiveProduct = () => {
        setHint(null);

        if (activeVariant == null) {
            setHint("We're sorry, but this product isn't available with the selected options! Please try another set of options.");
            return;
        }
        else if (filteredVariants.length > 1) {
            setHint("You have to choose options from above before adding this item to your cart!");
            return;
        }
        else if (!activeVariant.availableForSale) {
            if (product.hasOnlyDefaultVariant) setHint("We're sorry, but this product is out of stock at the moment.")
            else setHint("We're sorry, but the product is not available with the selected options.");
            return;
        }

        addCartLine(activeVariant.shopifyId);
    };

    const hintRef = useRef(null);

    const isOutOfStock = useMemo(() => product?.tracksInventory && !activeVariant.availableForSale, [product, activeVariant]);

    return activeVariant == null ? null : <div>

        <Btn
            onClick={buyActiveProduct}
            label={isOutOfStock ? 'Out of Stock' : 'Add to Cart'}
            icon="bi-bag-plus-fill"
            labelClassName='px-2 py-1'
            disabled={isOutOfStock}
            className={`text-white text-xl bg-success ${className}`}
        />
        <CSSTransition in={hint != null} nodeRef={hintRef} timeout={2000} classNames={{ ...shakeAnimationStyles }} >
            <div ref={hintRef} className="text-red-600 mt-2">
                { hint }
            </div>
        </CSSTransition>

    </div>
}