import React, { useContext } from 'react';
import ProductContext from '../../../../context/product-context';
import ProductOption from './option';

export default function ProductOptions({ className }) {
    const { product } = useContext(ProductContext);
    const options = product?.options ?? [];

    return <div className={className}>
        { options.map((option, i) => <ProductOption className='mb-2' key={i} {...option} />) }
    </div>
}