import React, { useContext } from 'react';
import { CarouselContext } from './carousel';

export default function CarouselTracker({ className }) {
    const { items, index, skip } = useContext(CarouselContext);
    
    return <div className={`flex justify-center text-gray-500 space-x-1 text-xs ${className}`}>
        { items.map((_, i) =>
            <i key={i} onClick={() => skip(i)} className={`bi ${i == index ? 'bi-circle-fill' : 'bi-circle'}`} />
        ) }
    </div>
}