import React, { createContext, useEffect, useState, useMemo } from 'react';
import { useContext } from 'react';
import ProductsContext from './products-context';

const DEFAULT = { product: null, variant: null };
const ProductContext = createContext(DEFAULT);

export function ProductContextProvider({ children, shopifyId }) {

    const { products } = useContext(ProductsContext);
    const [product, setProduct] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});
    const filteredVariants = useMemo(() => {
        const variants = product?.variants ?? [];
        const selectedOptionEntries = Object.entries(selectedOptions).filter(([name, value]) => value !== null);
        const filters = selectedOptionEntries.map(
            ([selectedOptionName, selectedOptionValue]) => (variant) => variant.selectedOptions
                .some((sub) => sub.name == selectedOptionName && sub.value == selectedOptionValue));
        return variants.filter(variant => filters.every(filter => filter(variant)));
    }, [product, selectedOptions]);

    const activeVariant = useMemo(() => filteredVariants[0], [filteredVariants]);

    useEffect(
        () => setProduct(products.find(sub => sub.shopifyId == shopifyId)),
        [products]
    );

    const setOption = (name, value) => setSelectedOptions({ ...selectedOptions, [name]: value });

    const value = { ...DEFAULT, product, activeVariant, filteredVariants, setOption };
    return (
        <ProductContext.Provider value={value}>
            {children}
        </ProductContext.Provider>
    );

}

export default ProductContext;