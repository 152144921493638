import React from "react";
import { useContext } from "react";
import ResponsiveContext from "../../../../context/responsive-context";
import ProductImagesGrid from "./grid";
import ProductImagesCarousel from "./carousel";

export default function ProductImages() {
    const { isMobile } = useContext(ResponsiveContext);

    return isMobile
        ? <ProductImagesCarousel />
        : <ProductImagesGrid />

}