import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useContext, useState, useEffect } from "react";
import ProductContext from "../../../../context/product-context";
import * as styles from './grid.module.css';

export default function ProductImagesGrid() {

    const { product } = useContext(ProductContext); 

    const [images, setImages] = useState([]);

    useEffect(() => {
        if (product == null) return;
        setImages(product.images.map(image => getImage(image.gatsbyImageData)));
    }, [product]);

    return product == null ? null : <div className={styles.imageGrid}>
        { images.map((image, i) => <GatsbyImage loading={i < 2 ? 'eager' : 'lazy'} imgClassName="rounded-lg" className={styles.image} key={i} image={image} />) }
    </div>;

}