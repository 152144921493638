import { onChange } from '@builder.io/react';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { createContext } from 'react';
import SelectTypeButton from './type/button';
import SelectTypeGroup from './type/group';

const TYPES = {
    button: SelectTypeButton,
    group: SelectTypeGroup
};

export const SelectContext = createContext({ value: null });
export default function Select({ type, children, className, onChange }) {

    const SelectType = TYPES[type];
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState({});

    const activeRef = useMemo(() => options[value]?.ref, [value]);

    const registerOption = (label, value, ref) => setOptions({
        ...options,
        [value]: { label, ref }
    });

    const unregisterOption = (value) => setOptions({ ...options, [value]: undefined });

    useEffect(() => {
        if (onChange == undefined) return;
        onChange(value);
    }, [value]);

    return <SelectContext.Provider value={{ type, value, activeRef, setValue, registerOption, unregisterOption }}>
        <SelectType className={className}>
            { children }
        </SelectType>
    </SelectContext.Provider>
    
}