import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useContext } from 'react';
import ProductContext from '../../../../../context/product-context';
import SelectOption from '../../../../material/select/option/option';
import Select from '../../../../material/select/select';

const COLOR_QUERY = graphql`{
  allBuilderModels {
    shopifyProductMetadata {
      data {
        shopifyId
        colorOptions
      }
    }
  }
}`;


export default function ProductOptionColor({ className, onChange, ...option }) {
    const { product } = useContext(ProductContext);
    
    const query = useStaticQuery(COLOR_QUERY);
    let productColorOptions = query.allBuilderModels.shopifyProductMetadata
        .find(sub => sub.data.shopifyId == product.shopifyId)
        ?.data?.colorOptions
        
    if (productColorOptions == null) productColorOptions = option.values.map(val => ({
      colorOptionName: val,
      colors: [{ colorCode: val.toLowerCase() }]
    }));

    // TODO: Combine Shopify variants with Builder variants and handle Shopify variants without Builder data.

    return <div className={className}>
        <span className='mb-2'>Color</span>
        <Select type='group' className='flex' onChange={onChange}>
            { productColorOptions.map((option, i) => <SelectOption
                key={i}
                value={option.colorOptionName}
                activeClassName='border-none ring-2 ring-offset-2 ring-primary-400'
                className='mr-2 border-solid border-2 border-slate-300 cursor-pointer transition-all -rotate-45 rounded-full overflow-hidden grid h-10 w-10'>
                { option.colors.map(({ colorCode }, e) => 
                    <div key={e} className='h-full' style={{ backgroundColor: colorCode }} />
                ) }
            </SelectOption>) }
        </Select>
    </div>
}