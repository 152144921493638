import React from "react";
import { useContext } from "react";
import ProductContext from "../../../context/product-context";
import Price from "../price/price";

export default function ProductPrice({ className, ...pass }) {
    const { product, activeVariant } = useContext(ProductContext);

    return activeVariant == null ? null : <span className={className}>
        <Price {...pass} isSimplePrice price={activeVariant.price} comparisonPrice={activeVariant.compareAtPrice} />
        { product.price }
    </span>
}